<div id="works" class="col-12 h-100">
  <div *ngIf="posts && posts.length == 0">
    No hay trabajos para mostrar
  </div>

  <ng-container *ngIf="posts && posts.length > 0">
    <div class="row">

      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
        *ngFor="let post of posts | paginate: {itemsPerPage: itemsPerPage, currentPage: page}">

        <div class="card h-100">
          <img class="card-image-top w-100 img-fluid" [src]="post.thumbnail" />
          <div class="card-body">
            <h3 class="card-title">{{post.title}}</h3>
            <p class="card-text" [innerHTML]="post.excerpt"></p>
          </div>
          <div class="card-footer">
            <a class="btn btn-block btn-primary" role="button" [href]="post.url" target="_blank">Ir al post</a>
          </div>
        </div>
      </div>

    </div>

    <div class="row" *ngIf="posts.length > itemsPerPage">
      <div class="col-12 text-center mb-5">
        <div class="pagination-footer">
          <pagination-controls nextLabel="Siguiente" previousLabel="Anterior" (pageChange)="page = $event">
          </pagination-controls>
        </div>
      </div>
    </div>

  </ng-container>


  <ddr-spinner [embedded]="true"></ddr-spinner>
</div>