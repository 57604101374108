<div id="experience" class="row">
    <div class="col-12 h-100">

        <h1>Experiencias</h1>
        <table class="table table-striped  table-responsive">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Empresa</th>
                    <th>Puesto</th>
                    <th>Tareas puesto</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of experiences">
                    <td>{{row.rango}}</td>
                    <td>{{row.compania}}</td>
                    <td>{{row.posicion}}</td>
                    <td>{{row.descripcion}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <ddr-spinner></ddr-spinner>
</div>