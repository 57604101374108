<div id="skills" class="row">
  <div class="col-12 h-100">
    <div *ngFor="let skill of skills" class="progress" [ngClass]="skill.class_color.color">
      <div class="progress-bar progress-bar-striped active" [ngClass]="skill.class_color.class_progress"
        [ngStyle]="{'width.%': skill.valor}">
        <div class="progress-value">{{skill.nombre+' ('+skill.valor+'%)'}}</div>
      </div>
    </div>
  </div>

  <ddr-spinner></ddr-spinner>

</div>