<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
    aria-expanded="false" aria-label="Menu de Navegacion">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbar">
    <ul class="navbar-nav">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['inicio']">{{'label.home' | translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['datos-personales']">Datos personales</a>
      </li>
      <li *ngIf="showSkills" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['habilidades']">Habilidades</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['estudios']">Estudios</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['experiencia-laboral']">Experiencia</a>
      </li>
      <li *ngIf="showContact" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['contacto']">Contacto</a>
      </li>
      <li *ngIf="showWorks" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['trabajos']">Trabajos</a>
      </li>
      <li *ngIf="showCourses" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['cursos']">Cursos</a>
      </li>
      <li *ngIf="showRepositories" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['repositorios']">Repositorios</a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="downloadCV()">
        <a class="btn btn-primary text-light">Descargar CV</a>
      </li>
    </ul>

  </div>
</nav>




