<div class="container-fluid p-0 h-100">
  <div class="row no-gutters h-100">
    <div class="col-lg-3 col-md-3 col-sm-12 col-12">
        <sidebar></sidebar>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-12 col-12 h-100 panel-left">
      <div class="row no-gutters h-100">
        <div class="col-12">
          <header-comp></header-comp>
        </div>
        <div class="content col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

